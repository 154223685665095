import { types } from "../types";

const initialState = {
  isOpen: false,
  items: [],
  isNewNotification: false,
};

export default function notificationsReducer(state = initialState, action) {
  const { type, payload, notifications } = action;

  switch (type) {
    case types.ADD_NOTIFICATION:
      return { ...state, items: [payload, ...state.items] };
    case types.MARK_ALL_AS_READ:
      return { ...state, items: payload };
    case types.UPDATE_NOTIFICATIONS:
      return { ...state, items: notifications };
    case types.DELETE_NOTIFICATION:
      return {
        ...state,
        items: state.items.filter(item => item.fake_id !== payload),
      };
    case types.UPDATE_NEW_NOTIFICATION:
      return { ...state, isNewNotification: payload };
    default:
      return state;
  }
}
