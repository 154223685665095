export default {
  server_log: {
    "1Hour ago": "vor 1 Stunde",
    "Logging date": "Protokollierungsdatum",
    Time: "Zeit",
    "Device name": "Gerätename",
    Acc: "Bes",
    System: "System",
    Battery: "Batterie",
    GPS: "GPS",
    laser: "Laser",
    Error: "Fehler",
    Abnormality: "Anomalie",
  },
};
