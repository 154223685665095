import _ from "lodash";
import {
  types,
  FAVORITE_POINT,
  UNFAVORITE_POINT,
  UPDATE_THEME,
  UPDATE_PANEL_MODE,
  UPDATE_COMPANY_ID,
} from "../types";

const initialState = {};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_ACCOUNT:
      if (_.isEmpty(action.account)) return state;
      return { ...action.account, companyId: null };

    case FAVORITE_POINT:
      return {
        ...state,
        favoritePoints: [...state.favoritePoints, action.payload],
      };
    case UNFAVORITE_POINT:
      return {
        ...state,
        favoritePoints: state.favoritePoints.filter(
          id => id !== action.payload
        ),
      };
    case UPDATE_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case UPDATE_PANEL_MODE:
      return {
        ...state,
        isMobileView: action.payload,
      };
    case UPDATE_COMPANY_ID:
      return { ...state, companyId: action.payload };
    default:
      return state;
  }
}
