export function getTimeAgo(type = "day", number = 1) {
  const now = new Date();

  switch (type) {
    case "year":
      return now.getTime() - number * 365 * 24 * 60 * 60 * 1000;
    case "week":
      return now.getTime() - number * 7 * 24 * 60 * 60 * 1000;
    default:
      return now.getTime() - number * 24 * 60 * 60 * 1000;
  }
}
