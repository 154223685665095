export default {
  catenary_point: {
    "At risk": "In Gefahr",
    "Catenary point filter": "Punktfilter",
    "Checked points": "Geprüfte Kontrollpunkt",
    "Choose a specific trip & time": "Wählen Sie eine bestimmte Fahrt und Zeit",
    "City Name": "Stadtname",
    "Download Signal": "herunterladen",
    "Downloading...": "herunterladen...",
    "Dropper drop": "Hängerabsenkung",
    "Dropper rupture": "Hänger-Bruch",
    "Abnormality/Total": "Anomalie/Gesamt",
    "False alarm": "Fehlalarm",
    "Fault category": "Fehlerkategorie",
    "Go to check": "Zur Überprüfung gehen",
    "Ignored points": "Ignorierte Kontrollpunkte",
    "Make a report": "Berichtserstellung",
    "Need repair": "Aktionr erforderlich",
    "Need to repair": "Aktionr erforderlich",
    "Need to check on": "Bedarf einer Sie überprüfung",
    "No.of abnormality": "Anzahl der Anomalien",
    "NoNeed repair": "keine  AKtion erforderlich",
    "No Need to repair": "keine erforderlich",
    "Not Recorded": "Keine Aufnahme",
    "Not Requested": "Nicht gefordert",
    "Overhead drop": "Overhead-Drop",
    "Point Detail": "Kontrollpunktdetail",
    "Point status": "Punktstatus",
    "Request Seen": "Anfrage registriert",
    "Request Video": "Video anfordern",
    "Scratched C.W": "Zerkratztes C.W",
    "Shock Point": "Stosspunkt",
    "Sorry, this signal doesn't have data":
      "Tut mir leid, dieses Signal enthält keine Daten",
    "Sort by": "Sortieren nach",
    "Type/Total": "Typ/Gesamt",
    "Video Requested": "Video anfordert",
    "Zig-Zag": "Zickzack",
    Cantilever: "Ausleger",
    Checked: "Geprüft",
    Corrosion: "Korrosion",
    Counts: "Zählt",
    Created: "Erstellte ",
    abnormalities: "Anomalien",
    Favorite: "Favorit",
    found: "gefunden",
    Height: "Höhe",
    Ignore: "Ignorieren",
    Joint: "Zusammen",
    Last: "Zuletzt",
    New: "Neu",
    No: "Nein",
    Other: "Andere",
    Overlap: "Überlappung",
    points: "Punkte",
    Points: "Punkte",
    Railways: "Eisenbahnen",
    Received: "Empfangen",
    Repaired: "Bearbeitet",
    Requested: "gefordert",
    Requesting: "Anfordern",
    Sections: "Abschnitte",
    Speed: "Geschwindigkeit",
    Status: "Status",
    Time: "Zeit",
    Total: "Gesamt",
    Type: "Art",
    Unhealthy: "Schadhaft",
    Wearing: "Verschleiß",
    "imported data": "importierte Daten",
    "please check mark a row(s) to make report":
      "Bitte markieren Sie eine oder mehrere Zeilen, um einen Bericht zu erstellen.",
    "Load All": "Alle laden",
    "Are you sure you want to delete this abnormality?":
      "Sind Sie sicher, dass Sie diese Anomalie löschen möchten?",
    "Delete Abnormality": "Anomalie löschen",
    Delete: "Löschen",
    Cancel: "Stornieren",
    "The abnormality could not be removed.":
      "Die Anomalie konnte nicht entfernt werden.",
    "something went wrong.": "etwas ist schief gelaufen",
    "Imported abnormality was removed successfully":
      "Die importierte Anomalie wurde erfolgreich entfernt",
    "Abnormality removed": "Anomalie entfernt",
  },
};
