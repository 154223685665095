import { types } from "../types";

const initialState = {
  isReload: true,
  isLoading: false,
  showInUtc: false,

  signals: [],
  signalsIDs: [],

  highlight: null,
  toolsOpen: null,

  scrollToSignal: null,
  signalOffset: null,

  tripInfo: null,
  selectedTrip: null,

  mapOptions: {
    mapHeightActive: true,
    mapZigzagActive: true,
    mapShockPointsActive: true,
    mapForceActive: true,
    mapCrossDistanceActive: true,
    mapCableRemain: true,
    mapArcActive: true,
    mapF2Active: true,
    mapCityNameActive: true,
    mapSectionsActive: false,
    mapRailwayActive: false,
  },
};

export default function calendarReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CALENDAR_SIGNALS_REQUEST:
      return { ...state, isLoading: true };
    case types.CHANGE_CALENDAR_SIGNALS:
      return {
        ...state,
        signals: {
          ...state.signals,
          [payload.index]: { [payload.key]: payload.value },
        },
      };
    case types.UPDATE_CALENDAR_SIGNALS:
      return { ...state, signals: payload, isLoading: false };
    case types.UPDATE_CALENDAR_SIGNALS_IDS:
      return { ...state, signalsIDs: payload };
    case types.UPDATE_TRIP_INFO:
      return { ...state, tripInfo: payload };
    case types.UPDATE_SELECTED_TRIP:
      return { ...state, selectedTrip: payload };

    case types.SET_SHOW_IN_UTC:
      return { ...state, showInUtc: payload };

    case types.UPDATE_CALENDAR_HIGHLIGHT:
      return { ...state, highlight: payload };
    case types.UPDATE_CALENDAR_TOOLS_OPEN:
      return { ...state, toolsOpen: payload };

    case types.SET_SCROLL_TO_SIGNAL:
      return { ...state, scrollToSignal: payload };
    case types.SET_SIGNAL_OFFSET:
      return { ...state, signalOffset: payload };

    case types.UPDATE_CALENDAR_MAP_OPTIONS:
      return {
        ...state,
        mapOptions: { ...state.mapOptions, [payload.key]: payload.value },
      };
    case types.UPDATE_IS_RELOAD_QUERY_CALENDAR_TRIPS:
      return { ...state, isReload: payload };

    default:
      return state;
  }
}
