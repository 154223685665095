export default {
  map: {
    "Your Location": "Ihr Standort",
    Satellite: "Satellit",
    "Make a sections": "Abschnitte erstellen",
    Line: "Strecke",
    "Fit to country": "Passend zum Land",
    Finish: "Beenden",
    "Delete section": "Abschnitt löschen",
    Add: "Hinzufügen",
    Edit: "Bearbeiten",
    Cancel: "Stornieren",
    "Add Section": "Abschnitt hinzufügen",
    "Edit Section": "Abschnitt bearbeiten",
    "Section name": "Abschnittsname",
    State: "Zustand",
    Railway: "Eisenbahn",
    Country: "Land",
    Station: "Bahnhof",
    Company: "Unternehmen",
    "Section Type": "Abschnittstyp",
    "Sections Types": "Abschnittstypen",
    Copied: "Kopiert",
    Copy: "Kopieren",
    "Make an abnormality": "Machen Sie eine Anomalie",
    "Contact wire height overrun": "Überschreitung der Fahrdrahthöhe",
    "Contact wire zig-zag overrun": "Fahrdraht im Zickzack überfahren",
    "Occurrence of Auflauf": "Vorkommen von Auflauf",
    "Contact wire wearing": "Fahrdrahtverschleiß",
    "Occurrence of arc on carbon strip":
      "Auftreten eines Lichtbogens auf einem Kohlenstoffstreifen",
    "Contact force overrun": "Kontaktkraftüberschreitung",
    "Occurrence of impact signal": "Auftreten des Aufprallsignals",
  },
};
