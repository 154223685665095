import { types } from "../types";

const initialState = {
  isLoading: false,

  triggers: [],
};

export default function triggersReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_TRIGGERS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_TRIGGERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        triggers: payload,
      };
    case types.GET_TRIGGERS_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
