import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";

import {
  accountReducer,
  calendarReducer,
  catenaryPointsReducer,
  catenaryReducer,
  chooseTripReducer,
  deviceSettingsReducer,
  generalSettingsReducer,
  homeReducer,
  notificationsReducer,
  panelReducer,
  pantographsReducer,
  pantovisionReducer,
  pointdetailReducer,
  reportingReducer,
  simulationReducer,
  superpowerReducer,
  supervisionReducer,
  triggersReducer,
} from "./reducers";

const reducerRoot = combineReducers({
  account: accountReducer,
  calendar: calendarReducer,
  catenary: catenaryReducer,
  catenaryPoints: catenaryPointsReducer,
  chooseTrip: chooseTripReducer,
  deviceSettings: deviceSettingsReducer,
  generalSettings: generalSettingsReducer,
  home: homeReducer,
  notifications: notificationsReducer,
  panel: panelReducer,
  pantographs: pantographsReducer,
  pantovision: pantovisionReducer,
  pointdetail: pointdetailReducer,
  reports: reportingReducer,
  simulation: simulationReducer,
  superpower: superpowerReducer,
  supervision: supervisionReducer,
  triggers: triggersReducer,
});

const enhancer = composeWithDevTools(applyMiddleware(thunk));
const store = createStore(reducerRoot, enhancer);

export default store;
