import moment from "moment";
import { types } from "store/types";

const initialState = {
  loading: false,
  loading_top_left: false,
  loading_top_right: false,
  loading_bottom_left: false,
  loading_bottom_right: false,
  loadingPointStatus: false,
  loading_serverLog: false,
  loading_newEvent: false,

  loadingUserActivity: false,

  widgets: [],
  cards: [],
  date: {
    from: moment().subtract(2, "week").toISOString(),
    to: moment().toISOString(),
  },
};

export default function homeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_WIDGET_REQUEST:
      return { ...state, loading: true };
    case types.GET_WIDGET_TOP_LEFT_REQUEST:
      return { ...state, loading_top_left: payload };
    case types.GET_WIDGET_TOP_RIGHT_REQUEST:
      return { ...state, loading_top_right: payload };
    case types.GET_WIDGET_BOTTOM_LEFT_REQUEST:
      return { ...state, loading_bottom_left: payload };
    case types.GET_WIDGET_BOTTOM_RIGHT_REQUEST:
      return { ...state, loading_bottom_right: payload };

    case types.GET_USER_ACTIVITY_REQUEST:
      return { ...state, loadingUserActivity: true };
    case types.GET_USER_ACTIVITY_SUCCESS:
      return { ...state, loadingUserActivity: false };
    case types.GET_USER_ACTIVITY_FAILURE:
      return { ...state, loadingUserActivity: false };

    case types.GET_WIDGET_SUCCESS:
      return { ...state, loading: false, cards: payload };
    case types.GET_WIDGETS_SUCCESS:
      return {
        ...state,
        widgets: [
          ...state.widgets.filter(w => w?._id !== payload?._id),
          payload,
        ],
      };
    case types.GET_WIDGET_FAILURE:
      return { ...state, loading: false };

    case types.CREATE_WIDGET:
      return {
        ...state,
        widgets: [
          ...state.widgets.filter(w => w?._id !== payload?._id),
          payload,
        ],
      };

    case types.UPDATE_WIDGET_SUCCESS:
      return {
        ...state,
        widgets: [
          ...state.widgets.filter(widget => widget?._id !== payload.id),
          payload.data,
        ],
      };

    case types.GET_POINT_STATUS_REQUEST:
      return { ...state, loadingPointStatus: true };
    case types.GET_POINT_STATUS_SUCCESS:
      return { ...state, loadingPointStatus: false };

    case types.GET_SERVER_LOG_REQUEST:
      return { ...state, loading_serverLog: true };
    case types.GET_SERVER_LOG_SUCCESS:
      return { ...state, loading_serverLog: false };

    case types.GET_NEW_EVENTS_REQUEST:
      return { ...state, loading_newEvent: true };
    case types.GET_NEW_EVENTS_SUCCESS:
      return { ...state, loading_newEvent: false };

    case types.DELETE_WIDGET:
      return {
        ...state,
        widgets: state.widgets.filter(widget => widget?._id !== payload),
        cards: state.cards.filter(card => card._id !== payload), // Remove the deleted widget from cards as well
      };

    default:
      return state;
  }
}
