import "./style.scss";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { Spinner } from "@chakra-ui/react";

export function ParentLoader({ children }) {
  return createPortal(children, document.getElementById("panto-top-loader"));
}

export default function Loader(props) {
  return props.utils === "panelLayout" ? (
    <ParentLoader>
      <div className="loader-panel">
        <div className="loader-panel-line" />
        {/* <Spinner size="sm" /> */}
      </div>
    </ParentLoader>
  ) : (
    <div className="loader-pages">
      <Spinner size="lg" />
    </div>
  );
}

ParentLoader.propTypes = {
  children: PropTypes.node,
};

Loader.propTypes = {
  utils: PropTypes.string,
};
