import { types } from "store/types";

const initialState = {
  isLoading: false,
  isLoadingFilter: false,

  myTags: [],
  myProjects: [],

  search: "",

  filterTag: [],
  filterAccess: "all",
  filterCatenaryType: "all",
};

export default function simulationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_SIMULATION_PROJECTS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_SIMULATION_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingFilter: false,
        myProjects: payload.myProjects,
        myTags: payload.myTags,
      };
    case types.GET_SIMULATION_PROJECTS_FAILURE:
      return { ...state, isLoading: false };

    case types.DELETE_CATENARY:
      return {
        ...state,
        myProjects: state.myProjects.filter(p => p._id !== payload),
      };
    case types.DUPLICATE_CATENARY:
      return { ...state, myProjects: [payload, ...state.myProjects] };

    case types.SET_SIMULATION_SEARCH:
      return { ...state, search: payload };
    case types.SET_SIMULATION_PROJECTS_FILTER:
      return { ...state, [payload.key]: payload.value };

    default:
      return state;
  }
}
