import { types } from "store/types";
import moment from "moment";

const initialState = {
  loading: false,
  rendering: false,
  downloading: false,
  loading_signal: true,
  loading_figure: true,
  figure_failure: false,

  is_imported: false,

  point: {},

  status_code: 200,
  video_loaded: false,
  video_progress: 0,
  video_status: null,

  time: "",
  duration: 0,

  enable_sensors: [],
  main_sensor: null,

  device_id: "",
  signal_id: "",
  device_acc: 0,
  device_name: "",
  train_name: "",
  prev_signal: null,
  next_signal: null,

  gps: [],
  laser: [],
  std: null,

  summary: {},
  events: [],
  figures: [],
  add_figure_open: false,

  highlight: null,
  figure_properties: null,

  video: "",
  video_blob: null,
  playing: false,

  current_time: 0,

  video_duration: 0,
  signal_duration: 0,

  position: [0, 0],
  map_points: [],

  date: {
    from: moment().subtract(2, "week").toISOString(),
    to: moment().toISOString(),
  },
};

export default function supervisionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_SIGNAL_DATA_FAILURE:
      return { ...state, status_code: payload.statusCode };
    case types.SUPERVISION_CHANGE_STATUS_CODE:
      return { ...state, status_code: payload };

    case types.SUPERVISION_CHANGE_MAIN_SENSOR:
      return { ...state, main_sensor: payload };
    case types.SUPERVISION_CHANGE_DEVICE_ID:
      return { ...state, device_id: payload };

    case types.VIDEO_LOADED:
      return { ...state, rendering: false, video_loaded: true };
    case types.SET_BASIC_INFO:
      return { ...state, ...payload };
    case types.SET_CURRENT_TIME:
      return { ...state, current_time: payload };

    case types.UPDATE_SUPERVISION_HIGHLIGHT:
      if (!payload) return state;
      return {
        ...state,
        highlight: payload._id,
        is_imported: payload?.imported,
        device_id: payload?.device?._id,
        main_sensor: payload.imported ? null : payload.signal.sensor,
        gps: payload.imported
          ? [payload.gps.coordinates]
          : payload.signal.gps.map(i => i.coordinates),
      };

    case types.GET_VIDEO_REQUEST:
      return { ...state, rendering: true };
    case types.GET_VIDEO_SUCCESS:
      return { ...state, ...payload };
    case types.GET_VIDEO_FAILURE:
      return {
        ...state,
        rendering: false,
        video_blob: null,
        video_size: null,
        video_duration: 0,
        video_status: null,
        video_progress: 0,
        video_loaded: false,
      };

    case types.GET_FIGURE_REQUEST:
      return { ...state, loading_signal: true };
    case types.GET_FIGURE_SUCCESS:
      return {
        ...state,
        loading_signal: false,
        loading_figure: false,
        figure_properties: null,
        figures: [...state.figures, payload],
      };
    case types.GET_FIGURE_FAILURE:
      return {
        ...state,
        loading_signal: false,
        loading_figure: false,
        figures: [],
      };

    case types.GET_LASER_SUCCESS:
      return {
        ...state,
        laser: payload.laser,
        signal_duration: payload.signal_duration,
      };

    case types.GET_ADD_FIGURE_REQUEST:
      return { ...state, loading_figure: true, figure_failure: false };
    case types.GET_ADD_FIGURE_SUCCESS:
      return {
        ...state,
        loading_figure: false,
        figure_properties: null,
        figures: [...state.figures, payload],
      };
    case types.GET_ADD_FIGURE_FAILURE:
      return { ...state, loading_figure: false, figure_failure: true };

    case types.GET_SIGNAL_VIDEO_PROGRESS:
      return { ...state, video_progress: payload };

    case types.GET_SIGNAL_DATA_REQUEST:
      return {
        ...state,
        video_blob: null,
        figures: [],
        loading: true,
        figure_failure: false,
        loading_signal: true,
        figure_properties: null,
      };
    case types.GET_SIGNAL_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
        status_code: 200,
        loading: false,
        loading_signal: false,
      };

    case types.DOWNLOAD_SIGNAL_START:
      return { ...state, downloading: true };
    case types.DOWNLOAD_SIGNAL_END:
      return { ...state, downloading: false };

    case types.UPDATE_POSITION:
      return { ...state, position: payload };
    case types.UPDATE_SIGNAL_DURATION:
      return { ...state, signal_duration: payload };
    case types.UPDATE_VIDEO_DURATION:
      return { ...state, video_duration: payload };
    case types.UPDATE_FIGURE_PROPERTIES:
      return { ...state, figure_properties: payload };
    case types.UPDATE_FIGURE:
      return { ...state, figures: [...state.figures, payload] };

    case types.CHANGE_VIDEO_STATUS:
      return { ...state, video_status: payload };

    case types.UPDATE_ONE_FIGURE:
      return {
        ...state,
        figures: state.figures.map(figure =>
          figure.title === payload.title
            ? {
                ...figure,
                rms: payload.rms,
                fetching: payload?.fetching || false,
              }
            : { ...figure, fetching: false }
        ),
      };

    case types.DELETE_FIGURE:
      return {
        ...state,
        figures: state.figures.filter((figure, index) => index !== payload),
      };

    case types.UPDATE_POINT_DETAIL_DATE:
      return { ...state, date: payload };

    case types.GET_POINT_DETAIL_SUCCESS:
      return { ...state, loading_point: false, point: payload };

    case types.ADD_POINT_STATUS:
      return {
        ...state,
        point: {
          ...state.point,
          statusHistory: [...state.point.statusHistory, payload],
        },
      };

    case types.REMOVE_POINT_STATUS:
      return {
        ...state,
        point: {
          ...state.point,
          statusHistory: state.point.statusHistory.filter(
            s => s._id !== payload
          ),
        },
      };
    default:
      return state;
  }
}
