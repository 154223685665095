import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import CalendarTrip from "app/pages/CalendarTrips/language.de";
import CalendarTripsWidget from "app/pages/Home/Widgets/CalendarTrips/language.de";
import CatenaryPoints from "app/pages/CatenaryPoints/language.de";
import CatenaryPointSummaryWidget from "app/pages/Home/Widgets/PointsSummery/language.de";
import DeviceOnTrainWidget from "app/pages/Home/Widgets/DeviceOnTrains/language.de";
import DeviceSetting from "app/pages/DeviceSettings/language.de";
import GeneralSetting from "app/pages/GeneralSettings/language.de";
import ImportAbnormalityWidget from "app/pages/Home/Widgets/ImportedAbnormality/language.de";
import Map from "app/components/PantoMap/language.de";
import NewEventsWidget from "app/pages/Home/Widgets/NewEvents/language.de";
import Pantographs from "app/pages/Pantographs/language.de";
import Pantovision from "app/pages/Pantovision/language.de";
import PointDetail from "app/pages/SuperPower/Pointdetail/language.de";
import PointStatusSummeryWidget from "app/pages/Home/Widgets/PointsStatusSummery/language.de";
import ServerLogWidget from "app/pages/Home/Widgets/ServerLog/language.de";
import Simulation from "app/pages/Catenary/language.de";
import SuperPower from "app/pages/SuperPower/language.de";
import TriggerAlarm from "app/pages/Home/Widgets/TriggerAlarms/language.de";
import Views from "../views/language.de";
import Widgets from "app/pages/Home/language.de";

import en from "translations/en.json";
import de from "translations/de.json";

i18n.use(initReactI18next).init({
  resources: {
    en,
    de: {
      ...de,
      ...ImportAbnormalityWidget,
      ...CalendarTrip,
      ...CalendarTripsWidget,
      ...CatenaryPoints,
      ...CatenaryPointSummaryWidget,
      ...DeviceOnTrainWidget,
      ...DeviceSetting,
      ...GeneralSetting,
      ...NewEventsWidget,
      ...Pantographs,
      ...Pantovision,
      ...PointStatusSummeryWidget,
      ...ServerLogWidget,
      ...Simulation,
      ...PointDetail,
      ...TriggerAlarm,
      ...Views,
      ...Widgets,
      ...Map,
      ...SuperPower,
    },
  },
  lng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
