export default {
  new_events: {
    New: "Neu",
    abnormalities: "Anomalien",
    Total: "Gesamt",
    Type: "Art",
    "Point Detail": "Punktdetail",
    "Downloading...": "Download...",
    "Download Signal": "herunterladen",
    "Point status": "Status Kontrollpunkt",
    "Video Requested": "Video angefordert",
    Requesting: "Anfordern",
    "Request Video": "Video anfordern",
    "Not Requested": "Nicht angefordert",
    Requested: "Gefordert",
    "Request Seen": "Registrierte Anforderung",
    "Not Recorded": "Nicht Registriert",
    Received: "Empfangen",
    "No Abnormalities found": "Keine Anomalien gefunden",
    "See on map": "Auf der Karte sehen",
  },
};
