import { types } from "store/types";
import moment from "moment";

const initialState = {
  isLoading: false,

  reportIds: [],
  reports: [],

  devices: [],
  tripInfo: null,
  selectedTrip: null,

  signals: [],
  signalsIDs: [],

  date: {
    from: moment().subtract(2, "week").toISOString(),
    to: moment().toISOString(),
  },
};

export default function reportReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_REPORT_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_REPORTS_ID_SUCCESS:
      return {
        ...state,
        reportIds: payload,
      };
    case types.GET_REPORT_SUCCESS:
      return {
        ...state,
        reports: [...state.reports, payload],
      };
    case types.GET_REPORT_FAILURE:
      return { ...state, isLoading: false };

    case types.ADD_REPORT:
      return {
        ...state,
        reports: [...state.reports, payload],
      };
    case types.DELETE_REPORT:
      return {
        ...state,
        reports: state.reports.filter(item => item?.reviewId !== payload),
      };
    case types.UPDATE_REPORT_DATE:
      return { ...state, date: payload };
    case types.UPDATE_REPORT_SIGNALS:
      return { ...state, signals: payload };
    case types.UPDATE_REPORT_SIGNALS_IDS:
      return { ...state, signalsIDs: payload };
    case types.UPDATE_REPORT_TRIP_INFO:
      return { ...state, tripInfo: payload };
    case types.UPDATE_REPORT_SELECTED_TRIP:
      return { ...state, selectedTrip: payload };

    default:
      return state;
  }
}
