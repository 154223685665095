import { types } from "../types";

const initialState = {
  isLoading: false,

  selectedCompany: null,
  companies: [],

  accounts: [],
  settings: [],
};

export default function generalSettingsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_GENERAL_SETTINGS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_GENERAL_SETTINGS_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case types.GET_GENERAL_SETTINGS_FAILURE:
      return { ...state, isLoading: false };

    case types.ADD_ACCOUNT:
      return {
        ...state,
        companies: state.companies.map(company =>
          company._id === state.selectedCompany
            ? {
                ...company,
                members:
                  company?.members?.length > 0
                    ? [...company.members, payload]
                    : [payload],
              }
            : company
        ),
      };

    case types.EDIT_ACCOUNT:
      return {
        ...state,
        companies: state.companies.map(company =>
          company._id === state.selectedCompany
            ? {
                ...company,
                members: company.members.map(member =>
                  member._id === payload._id ? payload : member
                ),
              }
            : company
        ),
      };

    case types.UPDATE_COMPANY:
      return {
        ...state,
        companies: state.companies.map(company =>
          company._id === payload._id ? { ...company, ...payload } : company
        ),
      };

    case types.DELETE_ACCOUNT:
      return {
        ...state,
        companies: state.companies.map(company =>
          company._id === state.selectedCompany
            ? {
                ...company,
                members: company.members.filter(m => m._id !== payload),
              }
            : company
        ),
      };

    case types.SET_SELECTED_COMPANY:
      return { ...state, selectedCompany: payload };
    case types.SET_COMPANIES:
      return { ...state, companies: payload };

    default:
      return state;
  }
}
