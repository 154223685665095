import { types } from "../types";

const initialState = {
  isLoading: false,
  selectedDevice: "",

  files: [],
  devices: [],
};

export default function deviceSettingsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_DEVICES_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_DEVICES_SUCCESS:
      return { ...state, isLoading: false, devices: payload };
    case types.GET_DEVICES_FAILURE:
      return { ...state, isLoading: false };
    case types.UPDATE_SELECTED_DEVICE:
      return { ...state, selectedDevice: payload };
    case types.ADD_DIVICES_ACCESS:
      return {
        ...state,
        devices: {
          ...state.devices,
          device_access: [...state.devices.device_access, payload],
        },
      };
    case types.REMOVE_DEVICES_ACCESS:
      return {
        ...state,
        devices: {
          ...state.devices,
          device_access: state.devices.device_access.filter(
            s => s._id !== payload
          ),
        },
      };

    case types.UPDATE_DEVICE:
      return {
        ...state,
        devices: state.devices.map(access =>
          access._id === payload._id ? payload : access
        ),
      };

    case types.UPDATE_DEVICE_FILES:
      if (
        state.files.find(
          file =>
            `${file.type}-${file.deviceId}` ===
            `${payload.type}-${payload.deviceId}`
        )
      )
        return state;
      return { ...state, files: [...state.files, payload] };
    case types.DELETE_DEVICE_FILE:
      return {
        ...state,
        files: state.files.filter(f => f.src !== payload),
      };

    default:
      return state;
  }
}
