import { types } from "store/types";

const initialState = {
  isLoading: false,

  notifications: [],

  selectedDeviceBox: {
    x: 0,
    y: 0,
    width: 0,
  },

  tripsDate: {
    signals: [],
    gps: [],
  },
};

export default function chooseTripReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CHOOSE_TRIP_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_CHOOSE_TRIP_SUCCESS:
      return { ...state, isLoading: false };
    case types.GET_CHOOSE_TRIP_FAILURE:
      return { ...state, isLoading: false };

    case types.SET_CHOOSE_TRIP_LOADING:
      return { ...state, isLoading: payload };
    case types.SET_CHOOSE_TRIP_SELECTED_DEVICE_BOX:
      return { ...state, selectedDeviceBox: payload };
    case types.SET_TRIPS_DATE:
      return { ...state, tripsDate: payload };
    case types.SET_CHOOSE_TRIP_NOTIFICATIONS:
      return { ...state, notifications: payload };

    default:
      return state;
  }
}
