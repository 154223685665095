import { createGlobalStyle } from "styled-components";

import add from "assets/images/icons/add.svg";
import arrowDown from "assets/images/icons/navbar/arrow_down.svg";
import batteryLevel from "assets/images/icons/supervision/battery-level.svg";
import blueNotifications from "assets/images/icons/blue-notifications.svg";
import calendar from "assets/images/icons/calendar.svg";
import carBattery from "assets/images/icons/supervision/car-battery.svg";
import cog from "assets/images/icons/cog.svg";
import coordinatesFinder from "assets/images/icons/coordinates-finder.svg";
import customization from "assets/images/icons/navigation/customization.svg";
import deviceSetting from "assets/images/icons/navigation/device-setting.svg";
import deviceSettingLight from "assets/images/icons/navigation/device-setting-light.svg";
import eye from "assets/images/icons/login/eye.svg";
import help from "assets/images/icons/navigation/help.svg";
import home from "assets/images/icons/navigation/home.svg";
import homeLight from "assets/images/icons/navigation/home-light.svg";

import makeReport from "assets/images/icons/make-report.svg";
import mouse from "assets/images/icons/mouse.svg";
import notifications from "assets/images/icons/navbar/notifications.svg";
import pantographs from "assets/images/icons/navigation/pantographs.svg";
import pantographsLight from "assets/images/icons/navigation/pantographs-light.svg";
import pen from "assets/images/icons/pen.svg";
import points from "assets/images/icons/navigation/points.svg";
import refresh from "assets/images/icons/navbar/refresh.svg";
import reload from "assets/images/icons/reload.svg";
import report from "assets/images/icons/navigation/report.svg";
import reporting from "assets/images/icons/navigation/reporting.svg";
import reportingLight from "assets/images/icons/navigation/reporting-light.svg";
import search from "assets/images/icons/navbar/search.svg";
import section from "assets/images/icons/navigation/section.svg";
import setting from "assets/images/icons/setting.svg";
import settings from "assets/images/icons/navigation/settings.svg";
import settingsLight from "assets/images/icons/navigation/settings-light.svg";
import simulation from "assets/images/icons/navigation/simulation.svg";
import simulationLight from "assets/images/icons/navigation/simulation-light.svg";
import speedometer from "assets/images/icons/supervision/speedometer.svg";
import star from "assets/images/icons/star.svg";
import std from "assets/images/icons/supervision/std.svg";
import subnode from "assets/images/icons/supervision/subnode.svg";
import temperature from "assets/images/icons/supervision/temperature.svg";
import triggers from "assets/images/icons/navigation/triggers.svg";
import triggersLight from "assets/images/icons/navigation/triggers-light.svg";
import uploadPicture from "assets/images/icons/upload-picture.svg";
import uploadPictureDark from "assets/images/icons/upload-picture-dark.svg";
import removePicture from "assets/images/icons/remove-picture.svg";
import canclePicture from "assets/images/icons/cancle-picture.svg";
import uploadToFtp from "assets/images/icons/upload-to-ftp.svg";
import ways from "assets/images/icons/navigation/ways.svg";
import waysLight from "assets/images/icons/navigation/way-light.svg";
import catenaryPoints from "assets/images/icons/navigation/catenary-points.svg";
import catenaryPointsLight from "assets/images/icons/navigation/catenary-points-light.svg";

import share from "assets/images/icons/share.svg";
import pointdetail from "assets/images/icons/point_detail.svg";
import supervision from "assets/images/icons/supervision.svg";
import recyclebin from "assets/images/icons/setting/recyclebin.svg";
import setting2 from "assets/images/icons/setting/setting.svg";
import whiteHeightBg from "assets/images/icons/setting/white-height-bg.svg";

import whiteShock from "assets/images/icons/setting/white-shock.svg";
import yellowShock from "assets/images/icons/setting/yellow-shock.svg";
import redShock from "assets/images/icons/setting/red-shock.svg";
import address from "assets/images/icons/help/address.svg";
import telephone from "assets/images/icons/help/telephone.svg";
import email from "assets/images/icons/help/e-mail.svg";

import plusRadius from "assets/images/icons/plus-radius.svg";
import plus from "assets/images/icons/plus.svg";
import dismiss from "assets/images/icons/dismiss.svg";
import arrowOpenMenu from "assets/images/icons/arrow_open_menu.svg";
import chart from "assets/images/icons/chart.svg";
import selectListCaret from "assets/images/icons/selectList-caret.svg";

import player5mForward from "assets/images/icons/video_player/player-5m-forward.svg";
import player5mBackward from "assets/images/icons/video_player/player-5m-backward.svg";
import playerPlay from "assets/images/icons/video_player/player-play.svg";
import playerPause from "assets/images/icons/video_player/player-pause.svg";
import downloadCircle from "assets/images/icons/download-circle.svg";
import download from "assets/images/icons/video_player/download.svg";
import rightArrow from "assets/images/icons/right-arrow.svg";
import leftArrow from "assets/images/icons/left-arrow.svg";
import arrowLeftSolid from "assets/images/icons/arrow-left-solid.svg";
import northDirection from "assets/images/icons/north-direction.svg";

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    font-family: "Inter" !important;
    font-size: 13px;
    color: #b0b4c3;
    font-weight: 400;
    position: relative;
    // background: #383a3f;
    background:#2a2d34;
    height: 100%;
    overflow: hidden;
    user-select: none;
  }

  * {
    padding: 0;
    margin: 0;
    outline: none;
    line-height: 17px;

    /* &:focus-visible {
      box-shadow: 0px 0px 0px 1px inset #3e84ff;
    } */
  }

  ul {
    list-style: none;
  }

  a {
    color: #91b9fb;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    &:hover {
      color: #82affa;
      outline: none;
      text-decoration: none;
    }
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline-width: 0;
  }

  label {
    transition: 0.4s color;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #111;
    font-weight: 400;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  strong {
    font-weight: 400;
  }

  html,
  body,
  #panto-root {
    min-height: 100% !important;
    height: 100%;
    line-height: 1.5;
  }

  #panto-root {
    overflow-y: hidden;
  }

  #panto-superpower {
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  #panto-modals {
    position: absolute;
    top: 0;
    left: 0;
  }

  #panto-notifications {
    display: flex;
    position: fixed;
    right: 0;
    flex-direction: column;
    bottom: 0;
    max-width: 300px;
    z-index: 9999999999999;
    padding: 0 10px;
    transition: all 0.5s ease-in-out 0s;
  }

  .pointer-cursor {
    cursor: pointer;
  }

  .disabled label,
  label.disabled {
    color: #71737a;
  }

  .alert-message {
    width: 100%;
    text-align: center;

    span {
      color: #f44336;
      font-size: 11px;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b0b4c3;
    opacity: 1; /* Firefox */
  }

  ::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b0b4c3;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b0b4c3;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b0b4c3;
    opacity: 1; /* Firefox */
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b0b4c3;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b0b4c3;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b0b4c3;
  }

  .hide-scrollbar > div:first-child > div:first-child {
    &::-webkit-scrollbar {
      display: none;
    }

    margin-right: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Chakra UI */
  .chakra-radio__control {
    color: white !important;
  }

  /* React DatePicker Library */
  .react-datepicker-wrapper {
    width: auto;

    input {
      background: transparent;
      border: 0;
      outline: 0;
      color: #91b9fb;
      text-align: right;
      width: 100%;
    }
  }

  .react-datepicker {
    border-radius: 10px;
    background: #2f3032;
    border-color: #2f3032;

    .react-datepicker-time__header {
      color: white;
    }

    .react-datepicker__time-container {
      border-left-color: rgba(255, 255, 255, 0.2);
    }

    .react-datepicker__time-list {
      color: white;
      background: #2f3032;

      .react-datepicker__time-list-item:hover {
        background: rgb(38, 38, 38) !important;
      }
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
      border: 0 !important;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
      border-top-color: #2f3032 !important;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
      border-top-color: #2f3032 !important;
    }

    .react-datepicker__month-container {
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .react-datepicker__triangle::before,
    .react-datepicker__triangle::after {
      border-bottom-color: #2f3032 !important;
    }

    .react-datepicker__header {
      background: #2f3032;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .react-datepicker__current-month,
    .react-datepicker__day-name {
      color: #ffffff;
    }

    .react-datepicker__day,
    .react-datepicker__day--keyboard-selected {
      color: rgba(255, 255, 255, 0.8);
      border-radius: 40%;
      background: transparent;

      &:hover {
        background-color: #262626;
      }
    }

    .react-datepicker__day--selected {
      color: #ffffff;
      background-color: #fbb040;

      &:hover {
        background-color: #fbb040;
      }
    }
  }

  .panto-modal-messenger-wire-support,
  .panto-modal-messenger-wire,
  .panto-modal-stitch-wire,
  .panto-modal-contact-wire,
  .panto-modal-steady-arm,
  .panto-modal-dropper {
    .panto-row {
      margin-bottom: 0;
      align-items: center;
      justify-content: space-between;
    }

    label {
      flex: 1;
    }

    input {
      text-align: center;
    }
  }

  .panto-modal-messenger-wire .panto-modal-footer,
  .panto-modal-stitch-wire .panto-modal-footer,
  .panto-modal-contact-wire .panto-modal-footer {
    justify-content: space-between;

    p {
      max-width: 270px;
      font-size: 0.6rem;
      margin-bottom: 0;
    }

    div {
      display: flex;
    }
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }

  .panto-icon {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;

    // Icons
    &.ph-cog {
      background-image: url(${cog});
    }
    &.ph-calendar {
      background-image: url(${calendar});
    }
    &.ph-upload-picture {
      background-image: url(${uploadPicture});
    }
    &.ph-upload-picture-dark {
      background-image: url(${uploadPictureDark});
    }
    
    &.ph-remove-picture {
      background-image: url(${removePicture});
    }
    &.ph-cancle-picture {
      background-image: url(${canclePicture});
    }
    &.ph-mouse {
      background-image: url(${mouse});
    }
    &.ph-pen {
      background-image: url(${pen});
    }
    &.ph-setting {
      background-image: url(${setting});
    }
    &.ph-coordinates-finder {
      background-image: url(${coordinatesFinder});
    }
    &.ph-upload-to-ftp {
      background-image: url(${uploadToFtp});
    }
    &.ph-search {
      background-image: url(${search});
    }
    &.ph-arrow-down {
      background-image: url(${arrowDown});
    }
    &.ph-notifications {
      background-image: url(${notifications});
    }
    &.ph-blue-notifications {
      background-image: url(${blueNotifications});
    }
    &.ph-reload {
      background-image: url(${reload});
    }
    &.ph-refresh {
      background-image: url(${refresh});
    }
    &.ph-show-icon {
      background-image: url(${eye});
    }
    &.ph-hide-icon {
      background-image: url(${eye});
    }
    &.ph-home {
      background-image: url(${home});
    }
    &.ph-home-light {
      background-image: url(${homeLight});
    }
    &.ph-customization {
      background-image: url(${customization});
    }
    &.ph-points {
      background-image: url(${points});
    }
    &.ph-ways {
      background-image: url(${ways});
    }
    &.ph-ways-light {
      background-image: url(${waysLight});
    }
    &.ph-catenaryPoints {
      background-image: url(${catenaryPoints});
    }
    &.ph-catenaryPoints-light {
      background-image: url(${catenaryPointsLight});
    }
    &.ph-pantographs {
      background-image: url(${pantographs});
    }
    &.ph-pantographs-light {
      background-image: url(${pantographsLight});
    }
    &.ph-simulation {
      background-image: url(${simulation});
    }
    &.ph-simulation-light {
      background-image: url(${simulationLight});
    }
    &.ph-triggers {
      background-image: url(${triggers});
    }
    &.ph-triggers-light {
      background-image: url(${triggersLight});
    }
    &.ph-reporting {
      background-image: url(${reporting});
    }
    &.ph-reporting-light {
      background-image: url(${reportingLight});
    }
    &.ph-settings {
      background-image: url(${settings});
    }
    &.ph-settings-light {
      background-image: url(${settingsLight});
    }
    &.ph-help {
      background-image: url(${help});
    }
    &.ph-section {
      background-image: url(${section});
    }
    &.ph-report {
      background-image: url(${report});
    }
    &.ph-device-setting {
      background-image: url(${deviceSetting});
    }
    &.ph-device-setting-light {
      background-image: url(${deviceSettingLight});
    }
    &.ph-plus-radius {
      background-image: url(${plusRadius});
    }
    &.ph-plus {
      background-image: url(${plus});
    }
    &.ph-dismiss {
      background-image: url(${dismiss});
    }
    &.ph-menu-arrow-down {
      background-image: url(${arrowOpenMenu});
    }
    &.ph-north-direction {
    background-image: url(${northDirection});
  }
    &.ph-chart {
      background-image: url(${chart});
    }
    &.ph-list-caret {
      background-image: url(${selectListCaret});
    }
    &.ph-video-player-5m-forward {
      background-image: url(${player5mForward});
    }
    &.ph-video-player-5m-backward {
      background-image: url(${player5mBackward});
    }
    &.ph-video-player-play {
      background-image: url(${playerPlay});
    }
    &.ph-video-player-pause {
      background-image: url(${playerPause});
    }
    &.ph-video-player-download-circle {
      background-image: url(${downloadCircle});
    }
    &.ph-download {
      background-image: url(${download});
    }
    &.ph-right-arrow {
      background-image: url(${rightArrow});
    }
    &.ph-left-arrow {
      background-image: url(${leftArrow});
    }
    &.ph-arrow-left-solid {
      background-image: url(${arrowLeftSolid});
    }
    &.ph-email {
      background-image: url(${email});
    }
    &.ph-telephone {
      background-image: url(${telephone});
    }
    &.ph-address {
      background-image: url(${address});
    }
    &.ph-red-shock {
      background-image: url(${redShock});
    }
    &.ph-yellow-shock-bg {
      background-image: url(${yellowShock});
    }
    &.ph-white-shock-bg {
      background-image: url(${whiteShock});
    }
    &.ph-white-height-bg {
      background-image: url(${whiteHeightBg});
    }
    &.ph-setting-tool {
      background-image: url(${setting2});
    }
    &.ph-recycle-bin-tool {
      background-image: url(${recyclebin});
    }
    &.ph-supervision {
      background-image: url(${supervision});
    }
    &.ph-point-detail {
      background-image: url(${pointdetail});
    }
    &.ph-share {
      background-image: url(${share});
    }
    &.ph-make-report {
      background-image: url(${makeReport});
    }
    &.ph-add {
      background-image: url(${add});
    }
    &.ph-star {
      background-image: url(${star});
    }
    &.ph-battery-level {
      background-image: url(${batteryLevel});
    }
    &.ph-car-battery {
      background-image: url(${carBattery});
    }
    &.ph-speedometer {
      background-image: url(${speedometer});
    }
    &.ph-std {
      background-image: url(${std});
    }
    &.ph-subnode {
      background-image: url(${subnode});
    }
    &.ph-temperature {
      background-image: url(${temperature});
    }

    // Rotation
    &.ph-icon-ttb {
      transform: rotate(180deg);
    }
    &.ph-icon-btt {
      transform: rotate(180deg);
    }
    &.ph-icon-rtl {
      transform: rotate(90deg);
    }
    &.ph-icon-ltr {
      transform: rotate(-90deg);
    }

    // Size
    &.ph-icon-xs {
      width: 5px;
      height: 5px;
    }
    &.ph-icon-sm {
      width: 10px;
      height: 10px;
    }
    &.ph-icon-md {
      width: 20px;
      height: 20px;
    }
  }

  .styled-scrollbar {
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(225, 225, 225, 0.2);
      border-radius: 99px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(225, 225, 225, 0.5);
    }
    // scrollbar-face-color: rgba(225, 225, 225, 0.2); /* Firefox 63 compatibility */
    // scrollbar-track-color: #3d4048; /* Firefox 63 compatibility */
    // scrollbar-color: rgba(225, 225, 225, 0.2) #3d4048;
    // scrollbar-width: 1px;
  }
  
  * {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #3d4048;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(225, 225, 225, 0.2);
      border-radius: 99px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(225, 225, 225, 0.5);
    }
  }

// pagination's style
  .item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  width: 30px;
  margin: 0px 3px;
  padding: 2px 16px;

  /* a:hover {
    color: white !important;
  } */
  }

  .pagination-page a:hover {
    color: rgba(225, 225, 225, 0.75) !important;
  }

  .pagination {
  position: relative;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: transparent;
  width: 550px;
  height: 50px;
  list-style-type: none;
}

  .disabled-page{
  color: #808e9b !important;
  cursor: not-allowed !important;
}

.next a {
  position: absolute;
  color: white;
  border-left: solid 1px rgba(255, 255, 255, 0.2);
  font-size: 14px;
  width: 100px;
  height: 30px;
  right: 0;

  &:hover {
    color: rgba(225, 225, 225, 0.5);
  }
}

.previous a {
  position: absolute;
  color: white;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  font-size: 14px;
  width: 100px;
  height: 30px;
  left: 0;
  
  &:hover {
    color: rgba(225, 225, 225, 0.5);
  }
}

.break-item{
  color: rgb(251, 176, 64);
}

  .active a {
    background: rgb(251, 176, 64);
    border-radius: 99px;
    color: black;
    font-weight: 600;

    &:hover {
      color: rgba(0, 0, 0, 0.75) !important;
    }
  }
`;
