export default {
  superpower: {
    Pointdetail: "Punktdetail",
    Supervision: "Beaufsichtigung",
    Pantovision: "Pantovision",
    "we don't have highlight abnormality":
      "Wir haben keine Hervorhebungsanomalie",
    "we don't have signal": "Wir haben kein Signal",
    "we don't have point": "Wir haben keinen Sinn",
  },
};
