export default {
  calendar_trips: {
    "With gps": "Mit gps",
    "With speed": "Mit Geschwindigkeit",
    "With measured data": "Mit gemessenen Daten",
    "Device is ON": "Gerät ist EIN",
    "Device name": "Gerätename",
    "No. of Trips": "Anzahl Fahrten",
    "No trip found": "Keine Fahrt gefunden",
    "Choose duration from calendar": "Wählen Sie die Dauer das Datum",
    "See on map": "Auf der Karte sehen",
  },
};
