import { types } from "../types";
import { getTimeAgo } from "helpers/getDateTime";

const initialState = {
  eventsLoading: false,
  pantographs_loading: false,
  events: [],
  pantographs: [],

  selectedEvents: [],
  selectedPantographs: [],

  eventsTypes: [
    "acc",
    "height",
    "zigzag",
    "force",
    "cross_distance",
    "cableRemain",
    "arc",
    "f2",
  ],

  selectedTab: "abnormalities", // abnormalities, pantographs

  highlightedEvent: "",
  highlightedPantograph: "",

  selectedEventsFilter: "latest", // latest, specific
  selectedPantographsFilter: "pick",

  eventsFilterCounts: 10,
  eventsFilterRange: [getTimeAgo("week", 1), new Date()],

  pantographsFilterDate: 365,
  pantographsFilterRange: [getTimeAgo("week", 1), new Date()],

  sortBy: "time",
  toolsOpen: null, // eventId or pointId

  pantographsMapOptions: {
    mapHeightActive: true,
    mapZigzagActive: true,
    mapPointsActive: true,
    mapForceActive: true,
    mapCrossDistanceActive: true,
    mapCableRemain: true,
    mapArcActive: true,
    mapF2Active: true,
    mapCityNameActive: true,
    mapRailwaysActive: false,
    mapSectionsActive: false,
  },
};

export default function pantographsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PANTOGRAPHS_EVENTS_REQUEST:
      return { ...state, eventsLoading: true };
    case types.GET_PANTOGRAPHS_EVENTS_SUCCESS:
      return { ...state, eventsLoading: false, ...payload };
    case types.GET_PANTOGRAPHS_EVENTS_FAILURE:
      return { ...state, eventsLoading: false };

    case types.GET_PANTOGRAPHS_REQUEST:
      return { ...state, pantographs_loading: true };
    case types.GET_PANTOGRAPHS_SUCCESS:
      return { ...state, pantographs_loading: false, pantographs: payload };
    case types.GET_PANTOGRAPHS_FAILURE:
      return { ...state, pantographs_loading: false };

    case types.CHANGE_SELECTED_TAB:
      return { ...state, selectedTab: payload };
    case types.CHANGE_SELECTED_FILTER:
      return { ...state, selectedPantographsFilter: payload };
    case types.CHANGE_LAST_EVENTS_COUNTS:
      return { ...state, eventsFilterCounts: payload };
    case types.CHANGE_EVENT_PERIOD_FROM:
      return { ...state, eventPeriodFrom: payload };
    case types.CHANGE_EVENT_PERIOD_TO:
      return { ...state, eventPeriodTo: payload };
    case types.UPDATE_PANTOGRAPHS_SELECTED_TAB:
      return { ...state, selectedTab: payload };

    case types.UPDATE_HIGHLIGHTED_EVENT:
      return { ...state, highlightedEvent: payload };
    case types.UPDATE_HIGHLIGHTED_PANTOGRAPH:
      return { ...state, highlightedPantograph: payload };

    case types.UPDATE_SELECTED_EVENTS_FILTER:
      return { ...state, selectedEventsFilter: payload };
    case types.UPDATE_SELECTED_PANTOGRAPHS_FILTER:
      return { ...state, selectedPantographsFilter: payload };

    case types.UPDATE_EVENTS_FILTER_COUNTS:
      return { ...state, eventsFilterCounts: payload };
    case types.UPDATE_EVENTS_FILTER_RANGE:
      return { ...state, eventsFilterRange: payload };

    case types.UPDATE_PANTOGRAPHS_FILTER_DATE:
      return { ...state, pantographsFilterDate: payload };
    case types.UPDATE_PANTOGRAPHS_FILTER_RANGE:
      return { ...state, pantographsFilterRange: payload };

    case types.UPDATE_TOOLS_OPEN:
      return { ...state, toolsOpen: payload };
    case types.UPDATE_PANTOGRAPHS_SORT_BY:
      return { ...state, sortBy: payload };

    case types.UPDATE_SELECTED_EVENTS:
      return { ...state, selectedEvents: payload };
    case types.UPDATE_SELECTED_PANTOGRAPHS:
      return { ...state, selectedPantographs: payload };

    case types.UPDATE_PANTOGRAPHS_MAP_OPTIONS:
      return {
        ...state,
        pantographsMapOptions: {
          ...state.pantographsMapOptions,
          [payload.key]: payload.value,
        },
      };

    case types.UPDATE_EVENTS_TYPE_PANTOGRAPH:
      return { ...state, eventsTypes: payload.eventsTypes };

    default:
      return state;
  }
}
