import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const styles = {
  global: {
    body: {
      fontFamily: "Inter",
    },
  },
};

const colors = {
  brand: {
    white: "#ffffff",
    blue: "#4285f4",
    blueHover: "#3670cf",
    blueAlpha200: "rgba(66, 133, 244, 0.2)",
    blueDark: "#46526a",
    redDark: "#9a0303",
    yellow: "#fbb040",
    yellowActive: "#de9831",
    green: "#85eca0",
    orange: "#fd9e58",
    slate: "#71737a",
    gray: "#40434a",
    dark: "#363636",
    black: "#151516",
  },
  blue: {
    200: "#4285f4",
    300: "#3670cf",
    400: "#2464ca",
  },
  green: {
    200: "#1a603b",
    400: "#124027",
  },
  yellow: {
    200: "#fbb040",
    300: "#de9831",
    400: "#be8025",
  },
};

const components = {
  Button: {
    baseStyle: {
      lineHeight: "17px",
      fontWeight: 400,
      borderRadius: "full",
      _focus: {
        boxShadow: "none",
      },
    },
    sizes: {
      lg: { height: "36px", py: "8px", px: "16px", fontSize: "14px" },
      md: { height: "32px", py: "6px", px: "10px", fontSize: "14px" },
      sm: { height: "27px", py: "4px", px: "8px", fontSize: "13px" },
    },
    variants: {
      solid: {
        bg: "brand.blue",
        color: "white",
        _hover: {
          bg: "brand.blueHover",
        },
      },
      outline: {
        borderColor: "brand.blue",
        _hover: {
          bg: "brand.blueAlpha200",
        },
      },
    },
  },
  Tooltip: {
    baseStyle: {
      bg: "brand.gray",
      color: "white",
      borderColor: "#4467a5",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 4,
    },
    sizes: {
      lg: {
        fontSize: "16px",
        py: "9px",
        px: "12px",
      },
      md: {
        fontSize: "14px",
        py: "7px",
        px: "10px",
      },
      sm: {
        fontSize: "12px",
        py: "5px",
        px: "8px",
      },
    },
  },
  Spinner: {
    defaultProps: {
      speed: "1s",
    },
  },
  Skeleton: {
    defaultProps: {
      startColor: "rgba(255,255,255,0.4)",
      endColor: "rgba(255,255,255,0.1)",
    },
  },
};

const theme = extendTheme({ config, styles, colors, components });

export default theme;
