export default {
  device_on_train: {
    "Carbon strip": "Schleifleiste",
    "Last connection": "Letzte Verbindung",
    "Device setting": "Geräteeinstellung",
    acceleration: "beschleunigung",
    "status is": "status ist",
    "not received": "nicht erhalten",
    received: "erhalten",
  },
};
