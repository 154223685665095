import { types } from "../types";

const initialState = {
  backTo: null,
  closeAnimation: false,
};

export default function superpowerReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_BACKTO_SUPERPOWER:
      return { ...state, backTo: payload };
    case types.SET_CLOSE_ANIMATION_SUPERPOWER:
      return { ...state, closeAnimation: payload };
    default:
      return state;
  }
}
