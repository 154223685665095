export default {
  general_setting: {
    Monitoring: "Überwachung",
    From: "Von",
    To: "Zu",
    Simulation: "Simulation",
    "Remain credits": "Guthaben bleiben",
    Enterprise: "Unternehmen",
    Premium: "Prämie",
    Community: "Gemeinschaft",
    "No user found": "Kein Benutzer gefunden",
    Name: "Name",
    Position: "Position",
    Role: "Rolle",
    "Last online": "Zuletzt Online",
    "Edit Company": "Unternehmen bearbeiten",
    Disabled: "Behinderte",
    "Add Company": "Firma hinzufügen",
    "Add User": "Benutzer hinzufügen",
    UserName: "Nutzername",
    "Upgrade Plan": "Upgrade-Plan",
    "Upgrade request sent!": "Upgrade-Anfrage gesendet!",
    "The support team will contact you as soon as possible.":
      "Das Support-Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.",
    "Sorry, we couldn't upgrade!":
      "Entschuldigung, wir konnten kein Upgrade durchführen!",
    "Try again later.": "Versuchen Sie es später noch einmal.",
    "Emails address": "E-Mail-Adresse",
    "Train speed more than": "Zuggeschwindigkeit mehr als",
    "More than": "Mehr als",
    "have passed since the previous trip":
      "seit der letzten Reise vergangen sind",
    min: "min",
    "have passed since the last GPS data":
      "seit den letzten GPS-Daten vergangen sind",
    "Train speed considered zero when GPS speed is less than":
      "Die Zuggeschwindigkeit wird als Null betrachtet, wenn die GPS-Geschwindigkeit kleiner als ist",
    "Point Meaning": "Punkt Bedeutung",
    "Close abnormality distance": "Anomalieabstand schließen",
    Gps: "Gps",
    "Minimum Speed": "Mindestgeschwindigkeit",
    "Import data": "Daten importieren",
    "Trip Meaning": "Bedeutung der Reise",
    "Total meaning": "Gesamtbedeutung",
    "Abnormality meaning": "Abnormität bedeutung",
    "Critical errors": "Kritische Fehler",
    "Simulation custom catenary":
      "Simulation einer benutzerdefinierten Oberleitung",
    "Advanced setting": "Erweiterte Einstellung",
    "Ignore GPS point if": "GPS-Punkt ignorieren, wenn",
    "The width of the range rectangle": "Die Breite des Bereichsrechtecks",
    "The new abnormality takes place": "Die neue Anomalie findet statt",
    "at the previous point, if": "am vorherigen Punkt, wenn",
    "Something went wrong": "Etwas ist schief gelaufen",
    "The data could not be added.":
      "Die Daten konnten nicht hinzugefügt werden.",
    "Data added": "Daten hinzugefügt",
    "The data added successfully.": "Die Daten wurden erfolgreich hinzugefügt.",
    "The data could not be removed.":
      "Die Daten konnten nicht entfernt werden.",
    "Data removed": "Daten entfernt",
    "The data removed successfully.": "Die Daten wurden erfolgreich entfernt.",
    No: "Nein",
    Yes: "Yes",
    "Delete data": "Daten löschen",
    "Are you sure you want to delete data?":
      "Sind Sie sicher, dass Sie Daten löschen möchten?",
    "Import abnormalities": "Anomalien importieren",
    "Import signals": "Signale",
    Note: "Notiz",
    "Add Data": "Daten hinzufügen",
    "Remove data": "Daten entfernen",
    "download data": "Daten herunterladen",
    "Height abnormality": "HöhenAnomalie",
    "Height more than": "Höhe mehr als",
    "Height less than": "Höhe kleiner als",
    "Zig-zag abnormality": "Zick-Zack-Anomalie",
    "Zig-zag more than": "Zickzack mehr als",
    "Zig-zag less than": "Zickzack weniger als",
    "Acc abnormality": "Acc-Anomalie",
    "maximum compression ratio": "maximales Kompressionsverhältnis",
    "Impact duration": "Wirkungsdauer",
    "search to find abnormality until": "Suche nach Anomalie bis",
    fails: "scheitert",
    "Force abnormality": "Anomalie erzwingen",
    "Wearing abnormality": "Verschleiß Anomalie",
    "wearing less than": "trägt weniger als",
    "In same trip and with same type minimum distance that two abnormalities should have from each other":
      "In derselben Reise und mit demselben Mindestabstand, den zwei Anomalien voneinander haben sollten",
    "Company updated successfully": "Firma erfolgreich aktualisiert",
    "Company added successfully": "Firma erfolgreich hinzugefügt",
    "Validation Errors": "Validierungsfehler",
    "Enter company name": "Geben Sie den Firmennamen ein",
    "Select a map access": "Wählen Sie einen Kartenzugang aus",
    "Select a panel access": "Wählen Sie einen Panel-Zugriff aus",
    "Add a history entry": "Fügen Sie einen Verlaufseintrag hinzu",
    "Sorry, this password is incorrect :(":
      "Entschuldigung, dieses Passwort ist falsch :(",
    "Company deleted successfully!": "Firma erfolgreich gelöscht!",
    "Company not found!": "Firma nicht gefunden!",
    "Something went wrong!": "Etwas ist schief gelaufen!",
    "Are you sure you want to enable this company?":
      "Möchten Sie dieses Unternehmen wirklich aktivieren?",
    "Company enabled": "Unternehmen aktiviert",
    "Are you sure you want to disable this company?":
      "Möchten Sie dieses Unternehmen wirklich deaktivieren?",
    "Company disabled": "Unternehmen deaktiviert",
    Delete: "Löschen",
    Enable: "Ermöglichen",
    Disable: "Deaktivieren",
    Cancel: "Absagen",
    Save: "Speichern",
    Add: "Hinzufügen",
    "Contact person information": "Informationen zur Kontaktperson",
    "First name": "Vorname",
    "Last name": "Nachname",
    "Phone number": "Telefonnummer",
    "Company name": "Name der Firma",
    "Map access": "Kartenzugriff",
    Select: "Wählen",
    What: "Was",
    Credit: "Kredit",
    Plan: "Planen",
    "No history": "Keine Geschichte",
    Devices: "Geräte",
    "Web address": "Webadresse",
    "Catenary points": "Oberleitungspunkte",
    "Calendar & trips": "Kalender & Reisen",
    Pantographs: "Pantographs",
    Triggers: "Löst aus",
    Reporting: "Berichterstattung",
    Range: "Bereich",
    Threshold: "Schwelle",
    max: "max",
    "choose company": "Firma wählen",
    "set thresholds": "Grenzwerte festlegen",
    Acceleration: "Beschleunigung",
    Height: "Höhe",
    Zigzag: "Zickzack",
    Arc: "Arc",
    f2: "f2",
    Force: "Gewalt",
    "Cross distance": "Querdistanz",
    "Cable remain": "KabelRemain",
    "Threshold added": "Schwellenwert hinzugefügt",
    "The threshold could not be set.":
      "Der Schwellenwert konnte nicht festgelegt werden.",
    "The threshold set successfully.":
      "Der Schwellenwert wurde erfolgreich festgelegt.",
  },
};
