import Loadable from "react-loadable";

import { Suspense } from "react";
import { Route } from "react-router-dom";

import Loader from "app/components/PantoLoader";

const PanelLayout = Loadable({
  loader: () => import("./panelLayout"),
  loading: Loader,
});

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Route path="/" component={PanelLayout} />
    </Suspense>
  );
}
