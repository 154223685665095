import moment from "moment";
import { types } from "../types";

const initialState = {
  error: false,
  loading: false,
  showAll: true,

  point: {},

  highlightedEvent: "",

  date: {
    from: moment().subtract(2, "week").toISOString(),
    to: moment().toISOString(),
  },
};

export default function pantovisionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_PANTOVISION_REQUEST:
      return { ...state, loading: true };
    case types.GET_PANTOVISION_SUCCESS:
      return { ...state, loading: false, point: payload };
    case types.GET_PANTOVISION_FAILURE:
      return { ...state, loading: false, error: true };
    case types.UPDATE_HIGHLIGHTED_EVENTS:
      return {
        ...state,
        highlightedEvent: payload,
        error: payload === undefined,
      };
    case types.UPDATE_PANTOVISION_SHOW_ALL:
      return { ...state, showAll: payload };

    case types.UPDATE_PANTOVISION_DATE:
      return { ...state, date: payload };
    case types.CLEAR_PANTOVISION_ERROR:
      return { ...state, error: false };

    case types.ADD_PANTOGRAPHS_STATUS:
      return {
        ...state,
        point: {
          ...state.point,
          status_history: [...state.point.status_history, payload],
        },
      };
    case types.REMOVE_PANTOGRAPHS_STATUS:
      return {
        ...state,
        point: {
          ...state.point,
          status_history: state.point.status_history.filter(
            s => s._id !== payload
          ),
        },
      };

    default:
      return state;
  }
}
